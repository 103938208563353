import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import styles from "./TransformIdeas.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TransformIdeas = () => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();
  return (
    <section className={styles.marqueeBG} id="demo">
      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center" }}>
          <p
            className='fs26'
            style={{
              textAlign: "center",
              maxWidth: breakpoints.sm && 220,
              fontSize: breakpoints.sm ? 20 : 26,
            }}
          >
            {t("MARQUEE.LITTLE")}
          </p>
        </div>
        <div className='row'>
          <div className={styles.marquee}>
            <div className={styles.marquee_inner} aria-hidden='true'>
              <span>{t("MARQUEE.BIG")}</span>
              <span>{t("MARQUEE.BIG")}</span>
              <span>{t("MARQUEE.BIG")}</span>
              <span>{t("MARQUEE.BIG")}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TransformIdeas;
